import { StorageService } from './storage.service';
import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn, Router } from '@angular/router';

export const invitedUserGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  if (isUserInvitedStatus()) {
    router.navigate(['/m/applobby']);
  }
  return !isUserInvitedStatus();
};

function isUserInvitedStatus(): boolean {
  const storageService = inject(StorageService)
  const status = storageService.secureStorage.getItem('status');
  return status === 'invited'
}
