// @ts-nocheck
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AuthguardService } from './authguard.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ChildroleGuard {
  makeIt2 = false;
  constructor(private readonly router: Router, private readonly storageService: StorageService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      
      const roles = this.storageService.secureStorage.getItem('currentUserRoles');      
      roles.forEach((role:string) => {
        if(route.data?.allowedRoles.includes(role.toLowerCase())){
          this.makeIt2 = true;        
        }
      });
      if(!this.makeIt2){
        this.router.navigate(['/404']);
      }
      return this.makeIt2;     
  }
  
}

export const IsAdminGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthguardService).canActivate(route, state);
}