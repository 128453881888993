import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorIntercept } from './services/error.interceptor';
import { AuthguardService } from './services/authguard.service';
import { NavifyauthService } from './services/navifyauth.service';
import { RoleGuard } from './services/role.guard';
import { ChildroleGuard } from './services/childrole.guard';
import { CommonService } from './services/common.service';
import { TcGuard } from './services/tc.guard';
import { CoreMaModule } from './shared/core-ma/core-ma.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotfoundComponent } from './notfound/notfound.component';
import { LogoutComponent } from './logout/logout.component';
import { OneWebComponentsAngularModule } from '@one/angular';
import { MainModule } from './layout/main/main.module';
import { UnauthModule } from './layout/unauth/unauth.module';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './services/custom-router-reuse-strategy';
import { OrganizationManagementService } from "./services/organizationManagement.service";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotfoundComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    CoreMaModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    OneWebComponentsAngularModule,
    HttpClientModule,
    MainModule,
    UnauthModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    AuthguardService,
    NavifyauthService,
    RoleGuard,
    ChildroleGuard,
    CommonService,
    TcGuard,
    OrganizationManagementService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
