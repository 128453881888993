import { Component, OnInit } from '@angular/core';
import { NavifyauthService } from '../services/navifyauth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading:boolean = false;
  constructor(readonly navifyServ: NavifyauthService) { 
    
  }

   async ngOnInit() {
    await this.doLogin();
  }
  async doLogin() {
    this.isLoading = true;
    await this.navifyServ.loginToNavify();
  }

}
