export enum Menu {
  BASICS = 'BASICS',
  BUTTONS_INDICATORS = 'BUTTONS & INDICATORS',
  DATA_TABLE = 'DATA TABLE',
  FORM_CONTROLS = 'FORM CONTROLS',
  LAYOUT = 'LAYOUT',
  NAVIGATION = 'NAVIGATION',
  PATTERNS = 'PATTERNS',
  POPUP_MODALS = 'POPUP & MODALS',
  GRID = 'GRID',
  VIRTUAL_KEYBOARD = 'VIRTUAL KEYBOARD'
}
