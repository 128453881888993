import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { StorageService } from './storage.service';
import { AuthguardService } from './authguard.service';

@Injectable({
  providedIn: 'root'
})
export class TcGuard {
  trianingStatus = this.storageService.secureStorage.getItem('trianingStatus');
  tcAccepted = this.storageService.secureStorage.getItem('tcAccepted');
  
  makeIt = false;
  constructor(private readonly router: Router, private readonly storageService: StorageService) {
   }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.trianingStatus === 'completed' && this.tcAccepted === true) {
      this.makeIt = true;
    } else {
      this.router.navigate(['/404']);
    }
    return this.makeIt;
  }

}

export const IsAdminGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthguardService).canActivate(route, state);
}
