import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthRoutingModule } from './unauth-routing.module';
import { UnauthComponent } from './unauth.component';
import { OwcModule } from '../../owc/owc.module';
import { OneWebComponentsAngularModule } from '@one/angular';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';



@NgModule({
  declarations: [
    UnauthComponent,
    TermsConditionsComponent
  ],
  imports: [
    CommonModule,
    UnauthRoutingModule,
    OwcModule,
    OneWebComponentsAngularModule
  ],
  providers:[
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UnauthModule { }
