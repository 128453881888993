import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { i18n } from './i18n.en';
import { NavifyauthService } from './services/navifyauth.service';
import { Components } from '@one/web-components';
import {fromEvent, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: { hostID: 'app-component' },
})
export class AppComponent implements OnInit, OnDestroy {
  i18n = i18n;
  onlineEvent!: Observable<Event>;
  offlineEvent!: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage!: string;
  @ViewChild('internetStatus', { static: true }) internetStatus!: Components.OwcModalDialog;

  constructor(readonly titleService: Title, readonly navifyServ: NavifyauthService){
    this.titleService.setTitle(i18n.APP_TITLE);
  }

  ngOnInit(): void {
  this.onlineEvent = fromEvent(window, 'online');
  this.offlineEvent = fromEvent(window, 'offline');

  this.subscriptions.push(this.onlineEvent.subscribe(e => {
    this.connectionStatusMessage = 'Back to online';
    setTimeout(()=>{
      this.internetStatus.visible = false;
    },Number('3000'))

  }));

  this.subscriptions.push(this.offlineEvent.subscribe(e => {
    this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
    this.internetStatus.visible = true;
  }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
