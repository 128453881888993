import { CanActivateFn } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { CommonService } from '../../../projects/studies/src/app/services/common.service';
import { Dialog } from '@angular/cdk/dialog';
import { ConfirmationPopupComponent } from '../../../projects/studies/src/app/propose-study/common/confirmation-popup/confirmation-popup.component';



interface FileListObj {
  file: File
  name?: string
  size?: number
  path?: string
  status?: string
  lastModified?: number;
  userUuid?: string;
  projectKey?: string;
  chunkPercents: {
      [key: number]: number
  }
  fileProgress?: number
}

@Injectable({
providedIn: 'root'
})
export class FileProgressGuardService {
constructor(
  private readonly commonService: CommonService,
  private readonly dialog: Dialog
) { }
async canActivate(): Promise<boolean> {
  const fileProgressList: FileListObj[] = Object.values(this.commonService.dataIngressFileUploadData)
  const filteredData = fileProgressList.filter((fd) => fd['status'] === 'INP')
  if (filteredData.length !== 0) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
          data: {
              title: 'Warning',
              confiramtion_text: `Upload in progress. If you log off from application, all progress will be lost. Are you sure you want to log off?`,
              accept_btn: 'Yes',
              decline_btn: 'No'
          },
      })
      const result: boolean = await new Promise((resolve, reject) => {
          dialogRef.closed.subscribe(res => {
              resolve(res as boolean)
          });
      });

      return result

  }
  else {
      return true

  }
}
canMatch(): boolean {
  return true;
}
}

export const FileProgressGuard: CanActivateFn = (route, state) => {
  return inject(FileProgressGuardService).canActivate();
};
