import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AddMetaDataFormInter } from '../interfaces/common.interface';
import { snackbarService } from '@one/web-components';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  dataIngressFileUploadData = {};
  public statusClassType = [
    {
      status: 'Created',
      class: 'created'
    },
    {
      status: 'Creation in progress',
      class: 'inprogress'
    },
    {
      status: 'Draft',
      class: 'draft'
    },
    {
      status: 'Rejected',
      class: 'rejected'
    },
    {
      status: 'Failed',
      class: 'failed'
    },
    {
      status: '',
      class: ''
    },
  ]

  readonly subPageOverlay = new BehaviorSubject<boolean>(false);
  $subPageOverlay = this.subPageOverlay.asObservable();

  readonly selFilesList = new BehaviorSubject<any[]>([]);
  $selFilesList = this.selFilesList.asObservable();

  readonly datasetObj = new BehaviorSubject<any>({});
  $datasetObj = this.datasetObj.asObservable();

  readonly isReviewDone = new BehaviorSubject<AddMetaDataFormInter>({review: false, mdfValid:true});
  $isReviewDone = this.isReviewDone.asObservable();

  readonly stepperResetVal = new BehaviorSubject<number>(0);
  $stepperResetVal = this.stepperResetVal.asObservable();

  readonly configAuditSettings = new BehaviorSubject<boolean>(false);
  $configAuditSettings = this.configAuditSettings.asObservable();

  readonly allDatasetCall = new BehaviorSubject<boolean>(false);
  $allDatasetCall = this.allDatasetCall.asObservable();

  constructor() { }

  openCreateDataset(visible: boolean){
    this.subPageOverlay.next(visible);
  }

  triggerAllDatasetAPI(apiCall: boolean){
    this.allDatasetCall.next(apiCall);
  }

  sendSelFilesList(arr: any){
    this.selFilesList.next(arr);
  }

  sendDatasetObj(obj: any){
    this.datasetObj.next(obj);
  }

  sendIsReviewDone(obj: AddMetaDataFormInter){
    this.isReviewDone.next(obj);
  }

  stepperReset(numVal: number){
    this.stepperResetVal.next(numVal);
  }

  openConfigAuditSettings(visible: boolean){
    this.configAuditSettings.next(visible);
  }

  openSnackBar(msg: string){
    snackbarService.show({
      message: msg,
      snackbarProps: {
        duration: 7000,
        showClose: true,
        status: 'alert',
        variant: 'primary',
        direction: 'horizontal'
      }
    });
  }

  openSnackBarWithType(msg: string, errType: any){
    snackbarService.clearAll();
    snackbarService.show({
      message: msg,
      snackbarProps: {
        duration: 7000,
        showClose: true,
        status: errType,
        variant: 'primary',
        direction: 'horizontal'
      }
    });
  }
  getStatus(status: string){
    return this.statusClassType.filter((sts)=>
        sts.status.toLowerCase().trim() === status.toLowerCase().trim())[0].class
  }

}
