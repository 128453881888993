import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavifyauthService } from '../services/navifyauth.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  isLoading = false;
  constructor(public auth: NavifyauthService, readonly storage: StorageService, public router: Router) {
    
   }

   async ngOnInit() {
    this.isLoading = true;
    await this.signOut();
  }

  async signOut(){
    await this.auth.logOut();    
  }

}
