


    <div class="loadingcontainer">
        <div *ngIf="isLoading" class="loading-user-spinner">
            <mat-progress-spinner [diameter]="44" mode="indeterminate" class="spinner-class">
            </mat-progress-spinner>
        </div>
    </div>


    <owc-card *ngIf="termsAndConditionStatus" class="owc-card">
        <owc-component-header slot="header">  
          <owc-typography variant="title6" class="welcome"> {{i18n.TNC.TITLE}} </owc-typography>          
        </owc-component-header>  
        <div class="c-body">           
          <owc-typography variant="body2">{{ContentTC}} </owc-typography>
        </div>  
        <owc-component-footer slot="footer">  
         <div slot="suffix" class="suffix">
          <owc-button variant="secondary" (click)="onDecline()"> {{i18n.TNC.BTN_DECLINE}} </owc-button>  
          <owc-button (click)="onAccepted()"> {{i18n.TNC.BTN_ACCEPT}} </owc-button>  
         </div>  
        </owc-component-footer>
      </owc-card>

    <owc-card *ngIf="trainingStatus">
        <div class="c-body tc"> 
            <img src="../../../assets/images/warning.svg" alt="" />
             <br />
             <owc-typography variant="body2">{{i18n.TNC.DES_TRAINING}} </owc-typography>            
        </div>
      </owc-card>

    <owc-card *ngIf="roleStatus">        
        <div class="c-body tc"> 
            <img src="../../../assets/images/warning.svg" alt="" />
            <br />
            <owc-typography variant="body2">{{i18n.TNC.DES_ROLE}} </owc-typography>  
                    
        </div>
      </owc-card>

