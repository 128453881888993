// @ts-nocheck

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NavifyauthService } from '../../../services/navifyauth.service';
import { StorageService } from '../../../services/storage.service';
import { CommonService } from '../../../services/common.service';
import { i18n } from '../../../i18n.en';
import { Result, TC } from '../../../interfaces/common.interface';
import { snackbarService } from '@one/web-components';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsConditionsComponent implements OnInit {
  i18n = i18n;
  showTerms: boolean = false;
  completeTraining = false;
  accessUser: boolean = false;
  trainingStatus: boolean = false;
  termsAndConditionStatus: boolean = false;
  roleStatus: boolean = false;
  orgRole = '';
  trianingStatus = '';
  userUuid: string = '';
  isLoading: boolean = false;
  
  ContentTC: string | undefined = '';
  trianingStatusValue: string = '';
  tcAccepted: boolean = false;
  timer: string = '4000';

  constructor(readonly router: Router, readonly navifyServ: NavifyauthService, readonly storageService: StorageService,
  readonly commonService: CommonService) { }

  ngOnInit(): void {
    
    this.isLoading = true;
    const isNavifyRoleExist = this.storageService.secureStorage.getItem('isNavifyRoleExist');
    if (isNavifyRoleExist === 'true') {
      this.trianingStatus = this.storageService.secureStorage.getItem('trianingStatus');
      this.tcAccepted = JSON.parse(this.storageService.secureStorage.getItem('tcAccepted'));
      this.getRoleDetails();
      this.getTermsConditionStatus();
    }
    else {
      this.roleStatus = true;
      this.isLoading = false;
    }
  }

   logout() {
    this.navifyServ.logOut();
  }
  
  getTermsConditionStatus() {
    this.commonService.getTermsConditionStatus().subscribe({
      next: (data: TC)=>{
        this.ContentTC = data.result?.contents;
      },
      error: (err)=>{},
      complete: ()=>{}
    });

  }

  getRoleDetails() {
      if (this.trianingStatus === 'completed') {
        if (this.tcAccepted === true) {
          this.router.navigate(['m/applobby']);
        }
        else {
          this.termsAndConditionStatus = true;
        }
      } else if (this.trianingStatus === 'pending') {
        this.trainingStatus = true;
      }
    // }
    else {
      this.roleStatus = true;
    }
    this.isLoading = false;
  }

  onDecline() {
     
      snackbarService.show({
        message: i18n.TNC.DECLINE,
        snackbarProps: {
          duration: 5000,
          showClose: true,
          status: 'warning',
          variant: 'primary',
          direction: 'horizontal'             
        }
      });    
    
    setTimeout(() => {
      this.logout();
    }, Number('5000'));
  }

  onAccepted() {
    const userUUID = this.storageService.secureStorage.getItem('userUuid');
    this.termsAndConditionStatus = false;
    this.isLoading = true;    
      this.commonService.updateTermsConditionStatus(userUUID).subscribe({
        next: (result) => {
          this.commonService.getCurrentUserRole({userUuid: userUUID}).subscribe({
            next: (res)=>{  
            this.storageService.secureStorage.getItem('userUuid')  
             const ts = res['result']['roleDetails'][0]['trianingStatus'].toLowerCase();  
             const accept = this.tcAccepted = res['result']['tcAccepted'];  
             this.storageService.secureStorage.setItem('trianingStatus', ts);
             this.storageService.secureStorage.setItem('tcAccepted', accept);
             this.isLoading = false;
            this.router.navigate(['m/applobby']);
          },
            error: (err) => {},
            complete: ()=>{}
          });
        },
        error: (err) => {},
        complete: ()=>{}
    });
  }
}