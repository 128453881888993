import { Router, RoutesRecognized } from '@angular/router';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Menu } from '../../common/models/Menu';

import { Components } from '@one/web-components';
import { global_config } from '../../config';
import { StorageService } from '../../services/storage.service';
import { Profile } from '../../interfaces/common.interface';
import { i18n } from '../../i18n.en';
import { NavItems, NAV } from '../../core/nav';
import { CommonService } from '../../services/common.service';
import { style } from '@angular/animations';


@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html',
  styleUrls: ['./unauth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnauthComponent {  
  
  @ViewChild('headerElement', { static: true }) headerElement!: Components.OwcHeader;
  
  @ViewChild('buttonElement', { static: true })
  buttonElement!: Components.OwcButton;
  profile!: Profile;
  isToolTopShow: boolean = true; 
  @ViewChild('footerElement', { static: true }) footerElement!: Components.OwcFooter;
  @ViewChild('indicatorBadgeElement', { static: true }) indicatorBadgeElement!: Components.OwcIndicatorBadge;
  readonly Menu = Menu;
  
  leftMenuVisibility = false;
  userMenuVisibility = false;
  i18n = i18n;
  menuItems: NavItems[] = NAV;
  isLoading: boolean = false;
  constructor(readonly router: Router, readonly storageService: StorageService) {}

  ngOnInit() {    
    this.isLoading = true;
    this.profile = this.storageService.secureStorage.getItem('profile');
  }

  getShortName() {     
    return this.profile.displayName?.split(' ').map((n:string) => n[0]).join('');    
  } 

}
