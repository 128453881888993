import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneWebComponentsAngularModule } from '@one/angular';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OneWebComponentsAngularModule
  ],
  exports:[
    OneWebComponentsAngularModule
  ]
})
export class OwcModule { }
