<div class="layout">
    <owc-header #headerElement >
        <owc-header-row seperator elevation variant="primary" class="primary-slot main-header">
            <div>{{'Kamino'}}</div>
            <div class="right-section">
                <owc-typography
                    class="owc-typography owc-typography--variant-badge owc-typography--device-desktop pn"
                    variant="badge"
                    device="desktop">
                    {{profile.displayName}}
                </owc-typography>
                <div class="pr">
                    <owc-icon-button class="icon-button mr10" icon="user" id="popoverWithClick"></owc-icon-button>
                    <owc-tooltip anchor="popoverWithClick" placement="top" class="toolTip"> {{'My Account'}}
                    </owc-tooltip>
                    <owc-popover anchor="popoverWithClick" trigger="click" position="right" class="profileMenu">
                        <div class="wrap">
                            <div slot="header" class="header">
                                <owc-avatar class="ttc">{{getShortName()}}</owc-avatar>
                                <owc-typography variant="subtitle1">{{profile.displayName}}</owc-typography>
                                <owc-typography variant="body2">{{profile.username}}</owc-typography>
                            </div>
                            <div class="body">
                                <owc-divider class="mb-0"></owc-divider>
                                <owc-button variant="secondary" class="m16" routerLink="/logout">
                                    {{i18n.BTN_LOGOUT}}
                                </owc-button>
                            </div>
                        </div>
                    </owc-popover>
                </div>    
                <owc-icon-button class="icon-button mr10" icon="circle_help" id="tt_help" ></owc-icon-button>
                <owc-tooltip anchor="tt_help" placement="top"> {{i18n.LBL_HELP}} </owc-tooltip>
                <owc-logo class="brand-logo" name="roche_blue" size="mini"></owc-logo>
            </div>
        </owc-header-row>
    </owc-header>
    
    <div class="container mt20">
        <router-outlet></router-outlet>
    </div>
        <owc-footer #footerElement fix-bottom variant="global" class="footer">
            <div slot="bottom">
                <owc-typography #typographyElement>
                    © 2023 F. Hoffmann-La Roche Ltd
                </owc-typography>
            </div>
        </owc-footer>
</div>

