// @ts-nocheck
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NavifyauthService } from './navifyauth.service';
import { snackbarService } from '@one/web-components';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(readonly router: Router, readonly auth: NavifyauthService) { }

  intercept(
    request: HttpRequest,
    next: HttpHandler
  ): Observable<HttpEvent> {
    request = request.clone({
      setHeaders: { 'X-Navify-Tenant': environment.tenantAlias },
      withCredentials: true
    });

    return next.handle(request).pipe(
      map((event: HttpEvent) => event),
      catchError((error: HttpErrorResponse) => {

        /* istanbul ignore else */
        if (error.status === Number('401')) {                
          this.auth.logOut();
        }
        /* istanbul ignore else */
        if (error.status === Number('500')) {
          snackbarService.show({
            message: 'Server Error, Please contact administrator',
            snackbarProps: {
              duration: 5000,
              showClose: true,
              status: 'alert',
              variant: 'primary',
              direction: 'vertical'             
            }
          });          
        }
        
        return throwError(() => error);
      }));


  }
}
