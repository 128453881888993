
import {

  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  reuseRoutes = ['datasets']

  createKey(route: ActivatedRouteSnapshot) {
   const path = route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/')
    return path;
  }

  private readonly routeStore = new Map<string, DetachedRouteHandle>();
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig?.path as string
    return this.reuseRoutes.includes(path)
  }
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const path = route.routeConfig?.path;
    this.createKey(route)
    if (path) {
      this.routeStore.set(this.createKey(route), handle);
    }
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig?.path as string
    return this.reuseRoutes.includes(path) && !!this.routeStore.get(this.createKey(route))
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.routeStore.get(this.createKey(route)) as DetachedRouteHandle;
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
