import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  constructor(private readonly router: Router, private readonly storageService: StorageService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      const currentUser = this.storageService.secureStorage.getItem('isLoggedIn');
      if (currentUser === 'true') {      
        return true;
      }
      else {
        this.router.navigate(['/login'], {replaceUrl: true});
        return false;      
      }
  }
}
  export const IsAdminGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    return inject(AuthguardService).canActivate(route, state);
  }